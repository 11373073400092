import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo
      title='Everything Green | Mission Statement  '
      description='Consumers use us to inform themselves on the world of legalised and prescription cannabis. Clinics and physicians use to advertise and obtain new clients. '
    />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='Mission Statement' />
        <p>Simplifying Legalised and Prescription Cannabis in Australia</p>
        <h2>Beliefs</h2>
        <p>
          To Allow Anyone in Need, Access to Medicinal Cannabis To Provide
          Recent and Succinct Information on Legalised Cannabis To Petition
          Government for the Further Study and Promotion of Medical Cannabis
        </p>
        <h3>Company Name & ABN</h3>
        <p>Everything Green PTY LTD 86 650 126 828</p>

        <Divider />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
//TODO: Add internal links.
